<template>
    <ul class="sitemap-discounts-index">
        <template v-for="(month, keyA) in sitemap">
            <li v-if="month.discounts.length" :key="keyA" class="sitemap-discounts-index__letter-sec">
                <h3 class="sitemap-discounts-index__title">{{ months[month.month - 1] }} {{ month.year }}</h3>
                <ul class="sitemap-discounts-index__letter-list">
                    <li v-for="(discount, keyB) in month.discounts" :key="keyB">
                        <NuxtLink :to="variants[discount.type].path + '/' + discount.slug">{{
                            discount.title
                        }}</NuxtLink>
                    </li>
                </ul>
                <ButtonGeneric
                    class="mb-6 max-w-max border-site-primary p-0 text-sm font-semibold text-site-primary"
                    v-if="month.discounts.length === 8"
                    :info="{
                        text: 'Ver más',
                        link: '/sitemap/promociones/' + months[month.month - 1] + '-' + month.year,
                        icon: $assets.primary.arrowRight,
                        iconPosition: 'right',
                    }"
                />
            </li>
        </template>
    </ul>
</template>

<script lang="ts" setup>
import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { variants } = useVariantsDictionary()

type SitemapPromos = {
    discounts: { title: string; slug: number; type: 'coupon' | 'offer' }[]
    month: number
    year: number
}

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('stores-sitemap-data', () => {
    return $fetch<SitemapPromos[]>(endpoints.pages.siteMap.discounts, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
    }).catch((e) => e.data || false)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const sitemap = ref(responseData.value) as Ref<SitemapPromos[]>
const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
]
</script>
<style lang="postcss">
.sitemap-discounts-index {
    &__letter-sec {
        @apply py-4 sm:py-6 lg:py-8;
        &:not(:last-of-type) {
            @apply border-b border-gray-200;
        }
    }
    &__title {
        @apply block text-lg font-medium capitalize;
    }
    &__letter-list {
        @apply my-6 grid gap-5 leading-none xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4;
        a {
            @apply lg:hover:underline;
        }
    }
}
</style>
